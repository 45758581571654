const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://flash.dvgeo.app' : 'http://192.168.100.106:3042',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://flash.dvgeo.app' : 'http://192.168.100.106:3042',
    api: 'api/',
    apiSocket: 'flashdriver::1.2',
    nameDir: 'flashdriver',
    package: 'app.dvgeo.flashdriverdriver.passenger',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyDxEdMNvrp39rrcGTQ9w44XxtVSAETaUgA',
    appName: 'Flash Driver',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#F37C28',
    colorDark: '#EC1C24',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.flashdriver.passenger',
    playStoreDriverId: 'app.dvgeo.flashdriver.driver',
    appStorePassengerId: '6738511963',
    appStoreDriverId: '6738511965',
    email: "theflashsdriver@gmail.com",
};
export default config;
